import { BaseDto } from '@/shared/dtos/base-dto';

export class modelo extends BaseDto {
    public id_nutricionista_propietario !: number;
    public nombre_modelo !: string;
    public descripcion_modelo !: string;
    public id_dieta !: number;
    public sistema !: boolean;
    public fechacreado!:Date;
 } 
