import { BaseDto } from '@/shared/dtos/base-dto';

export class modeloAll extends BaseDto {
    public energia !: number;
    public glucidos_totales !: number;
    public polisacaridos !: number;
    public azucares !: number;
    public fibra !: number;
    public grasas_totales !: number;
    public agmi !: number;
    public agpi !: number;
    public ags !: number;
    public colesterol !: number;
    public proteinas_totales !: number;
    public proteinas_animales !: number;
    public proteinas_vegetales !: number;
    public total !: number;
    public porcen_hc !: number;
    public porcen_gr !: number;
    public porcen_pr !: number;
    public id_dieta !: number;
    public fecha !: string;
    public sistema !: boolean;
    public nombre_modelo !: string;
    public descripcion_modelo !: string;
    public id_nutricionista_propietario!:string;
}
