import { store } from '@/store/store';
import { modelo } from '@/shared/dtos/platillas-modelos/modelo';
import { modeloAll } from '@/shared/dtos/platillas-modelos/modelo-all';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'modeloModule',
    store,
    dynamic: true
})
class modeloModule extends VuexModule {
    public modelos: modeloAll[] = [];
    public modelo: modelo = new modelo();

    @Action({ commit: 'onGetmodelos' })
    public async getmodelos() {
        return await ssmHttpService.get(API.modelo);
    }

    @Action({ commit: 'onGetmodelo' })
    public async getmodelo(id: any) {
        return await ssmHttpService.get(API.modelo + '/' + id);
    }

    @Action
    public async guardarmodelo(modelo: modelo) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.modelo, modelo.toJson());
    }

    @Action({})
    public async Clonar_modelo(id_modelo: number) {
        return await ssmHttpService.post(API.modelo + '/clonar_modelo/' + id_modelo, null, true);
    }

    @Action({})
    public async Asignar_modelo_a({ id_modelo, id_paciente }: any) {
        return await ssmHttpService.post(API.modelo + '/asignar_modelo_a/' + id_modelo + '/' + id_paciente, null, true);
    }
    @Action({})
    public async ConveritirDietaEnModelo(modelo:modelo) {
        return await ssmHttpService.post(API.modelo + '/converitirdietaenmodelo', modelo.toJson(), true);
    }
    
    @Action
    public async modificarmodelo(modelo: modelo) {
        await ssmHttpService.put(API.modelo+ '/' + modelo.id, modelo);
        this.getmodelos();
    }

    @Action
    public async eliminarmodelo(modelo: modelo) {
        await ssmHttpService.delete(API.modelo + '/' + modelo.id, null, false);
        this.getmodelos();
    }

    @Mutation
    public onGetmodelos(res: modeloAll[]) {
        this.modelos = res ? res.map((x) => new modeloAll(x)) : []
    }

    @Mutation
    public onGetmodelo(res: modelo) {
        this.modelo = new modelo(res);
    }

}
export default getModule(modeloModule);