























































import { Component, Prop, Vue } from "vue-property-decorator";
import modeloModule from "@/store/modules/modelo-module";
import { modeloAll } from "@/shared/dtos/platillas-modelos/modelo-all";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
import { singleOrDoubleRowClick } from "./dblclickrow";
@Component({
  components: {}
})
export default class seleccionplantilla extends Vue {
  @Prop({ default: -1 }) id_paciente!: number;
  public search: string = "";
  public platilla_seleccionada: number = -1;

  public created() {
    modeloModule.getmodelos();
  }
  public get modelos() {
    return modeloModule.modelos;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre_modelo"
      },
      {
        text: "Descripción",
        value: "descripcion_modelo"
      },
      {
        text: "Energía",
        value: "energia"
      },
      { text: "Glúcidos", value: "porcen_hc" },
      { text: "Grasas", value: "porcen_gr" },
      { text: "Proteínas", value: "porcen_pr" },
      { text: "", value: "acciones" }
    ];
  }

  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }

  public asignar_platilla(item: modeloAll) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "primary",
      title: "Confirme asignación",
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "¿Seguro que quiere asignar la platilla ('" +
        item.nombre_modelo +
        "') al paciente? ",
      accept: () => {
        modeloModule
          .Asignar_modelo_a({
            id_modelo: item.id,
            id_paciente: this.id_paciente
          })
          .then(x => {
            this.$router.push({
              name: RouterNames.dieta,
              query: {
                id_dieta: UtilsEncript.Encriptar(x.id_dieta.toString()),
                id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString())
              }
            });
          });
      }
    });
  }
}
