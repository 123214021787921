var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelos,"search":_vm.search},on:{"click:row":function($event){_vm.dblclick($event, function() {}, _vm.asignar_platilla)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('div',[_c('div',[_vm._v("Plantillas de dietas")]),_c('div',{staticClass:"caption"},[_vm._v("Selecciona la platilla a asignar haciendo doble click sobre la fila o en botón "),_c('v-icon',{attrs:{"x-small":"","color":"green"}},[_vm._v("add")])],1)])]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.porcen_hc",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_hc ? item.porcen_hc : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#EA9F77"},model:{value:(item.porcen_hc),callback:function ($$v) {_vm.$set(item, "porcen_hc", $$v)},expression:"item.porcen_hc"}})]}},{key:"item.porcen_pr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_pr ? item.porcen_pr : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#66CCB7"},model:{value:(item.porcen_pr),callback:function ($$v) {_vm.$set(item, "porcen_pr", $$v)},expression:"item.porcen_pr"}})]}},{key:"item.porcen_gr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.porcen_gr ? item.porcen_gr : 0)+"%")]),_c('v-progress-linear',{attrs:{"color":"#DB4965"},model:{value:(item.porcen_gr),callback:function ($$v) {_vm.$set(item, "porcen_gr", $$v)},expression:"item.porcen_gr"}})]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"green darken-2"},on:{"click":function($event){return _vm.asignar_platilla(item)}}},[_c('v-icon',[_vm._v("add")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }